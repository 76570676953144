<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="app">
		<AppHeader fixed>
			<b-link
				:to="{name: 'MainHome'}"
				class="navbar-brand">
				<div
					:id="logoIds().navbar"
					class="ml-5 navbar-brand-full d-none d-sm-block"
					alt="Logo" />
			</b-link>
			<b-navbar-nav class="ml-auto">
				<CountryDropdown
					v-if="!$user.auth()"
					class="mr-3" />
				<LangDropdown class="mr-3" />
				<DefaultHeaderDropdownAccnt :show-only-login="true" />
			</b-navbar-nav>
		</AppHeader>
		<div class="app-body">
			<main class="main ml-0">
				<Breadcrumb
					:class="{'mb-0' : ['xs','sm'].includes(windowWidth)}"
					:list="translateList()" />
				<div
					:class="{'p-2' : ['xs','sm'].includes(windowWidth)}"
					class="container-fluid">
					<router-view :key="$route.fullPath" />
				</div>
			</main>
		</div>
		<TheFooter class="ml-0">
			<!--footer-->
			<div>
				{{ appName }}
				<span class="ml-1">&copy; {{ new Date().getFullYear() }}</span>
			</div>
			<div class="ml-auto">
				<span class="mr-1">{{ translate("powered_by") }}</span>
				<span v-html="poweredBy" />
			</div>
		</TheFooter>
	</div>
</template>

<script>
import Vue from 'vue';
import {
	Header as AppHeader,
	Footer as TheFooter,
	Breadcrumb,
} from '@coreui/vue';
import VCalendar, { setupCalendar } from 'v-calendar';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import isLoading from '@/components/Loading';
import { V_CALENDAR_CONFIG } from '@/settings/Dates';
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt';
import LangDropdown from './LangDropdown';
import CountryDropdown from './CountryDropdown';

Vue.component('isLoading', isLoading);
Vue.use(VCalendar, V_CALENDAR_CONFIG);

export default {
	name: 'Full',
	components: {
		AppHeader,
		TheFooter,
		Breadcrumb,
		DefaultHeaderDropdownAccnt,
		LangDropdown,
		CountryDropdown,
	},
	mixins: [DocumentTitle, WindowSizes],
	data() {
		return {
			appName: process.env.VUE_APP_NAME,
			poweredBy: process.env.VUE_APP_POWERED,
		};
	},
	computed: {
		name() {
			return this.$route.name;
		},
		list() {
			return this.$route.matched.filter((route) => route.name || route.meta.label);
		},
	},
	watch: {
		language(newVal) {
			this.changeDocumentTitle();
			setupCalendar({
				locale: newVal,
			});
		},
	},
	created() {
		setupCalendar({
			locale: this.language,
		});
	},
	methods: {
		translateNav(menuItems) {
			const menu = JSON.parse(JSON.stringify(menuItems));
			return menu.filter((item) => {
				if (typeof item.children !== 'undefined') {
					// if it has children, call this function again
					item.children = this.translateNav(item.children);

					if (item.children.length < 1) {
						// after call this function, the item must have children, if not, return null
						return null;
					}
				}

				if (item.children instanceof Array) {
					item.name = this.translate(item.name + process.env.VUE_APP_MENU_POSTFIX);
					return item;
				}

				if (this.hasAccessToPath(item.url)) {
					// string to show in menu
					item.name = this.translate(item.name + process.env.VUE_APP_MENU_POSTFIX);
					return item;
				}
				return null;
			});
		},
	},
};
</script>
